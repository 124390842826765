<template>
  <div class="reporting management-page">
    <div class="title" style="color: #383838; font-size: 24px;">Revenue Sharing</div>
    <AgGrid
        :apiurl = "apiUrl"
        :sideBar = true
        :aggFunc = "aggFunc"
        :defineDefs = "columnDefs"
        groupPanel = ''
        :excelOptions = "{fileName:'RevShare.xlsx'}"
        height="88%"
        @filterChanged = "filterModified"
    ></AgGrid>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import AgGrid from '@/components/AgGrid.vue';

const apiUrl = ref(`/api/revshare`)
const agref = ref()


onMounted(() => {});

const filterModified = (model) => {}
//['Parent', 'Child (Sub Merchant)','ID', 'Month', 'Transactions', 'Total','Avg','Rate', 'Collected Fees', 'Interchange', 'Surcharge', 'IC', 'Profit', '%', 'Payout','Client Fee', 'Auto', 'Owed']
const columnDefs = [
{ headerName: `Merchant`,
    children: [
    {
      headerName: 'Merchant',
      filter: 'agSetColumnFilter',
      valueGetter: (p) => { if(!p.data) return ''; return p.data.sub_merchant_name;},
    },
    {
      headerName: 'Parent',
      filter: 'agTextColumnFilter',
      valueGetter: (p) => { if(!p.data) return ''; return p.data.parent_name;}
    },
    {
      headerName: 'Date Onboarded',
      filter: 'agTextColumnFilter',
      valueGetter: (p) => { if(!p.data) return ''; return p.data.date_onboarded;}
    },
    ]},
    {
      headerName: 'Performance',
      children: [
    {
      headerName: 'Month',
      filter: 'agSetColumnFilter',
      valueGetter: (p) => { if(!p.data) return ''; return p.data.transaction_month;},
    },
    {
      headerName: 'Transactions',
      filter: 'agNumberColumnFilter',
      valueGetter: (p) => {
        if(!p.data) return null
         return parseInt(p.data.transactions) || 0;

      },
      valueFormatter: (p) => {
        if(!p.data) return null
         return parseInt(p.data.transactions) || 0;

      },
    },
    {
      headerName: 'Total Amount',
      filter: 'agNumberColumnFilter',
      aggFunc: 'total',
      valueGetter: (p) => {
        if(!p.data) return null
         return parseFloat(p.data.total_transaction_amount) || 0.00;

      },
      valueFormatter: (p) => {
        if(!p.data) return null
         return parseFloat(p.data.total_transaction_amount).toFixed(2) || 0.00;

      },
    },
    {
      headerName: 'Total Fees',
      filter: 'agNumberColumnFilter',
      valueGetter: (p) => {
        if(!p.data) return null
         return parseFloat(p.data.total_transaction_fee) || 0.00;

      },
      valueFormatter: (p) => {
        if(!p.data) return null
         return parseFloat(p.data.total_transaction_fee).toFixed(2) || 0.00;

      },
    },
    {
      headerName: 'Total Costing',
      filter: 'agNumberColumnFilter',
      valueGetter: (p) => {
        if(!p.data) return null
         return parseFloat(p.data.total_costing) || 0.00;

      },
      valueFormatter: (p) => {
        if(!p.data) return null
         return parseFloat(p.data.total_costing).toFixed(2) || 0.00;

      },
    },
    {
      headerName: 'Total Profit',
      filter: 'agNumberColumnFilter',
      valueGetter: (p) => {
        if(!p.data) return null
        return parseFloat(p.data.total_profit) || 0.00;

      },
      valueFormatter: (p) => {
        if(!p.data) return null
        return parseFloat(p.data.total_profit).toFixed(2) || 0.00;

      },
    },
    {
      headerName: 'Rev Share',
      filter: 'agTextColumnFilter',
      valueGetter: (p) => { if(!p.data) return ''; return p.data.rev_share;},
    },
    {
      headerName: 'Payout',
      filter: 'agNumberColumnFilter',
      valueGetter: (p) => {
        if(!p.data) return null
         return parseFloat(p.data.payout) || 0.00;

      },
      valueFormatter: (p) => {
        if(!p.data) return null
         return parseFloat(p.data.payout).toFixed(2) || 0.00;

      },
    },
    {
      headerName: 'Post-Payout Profit',
      filter: 'agNumberColumnFilter',
      valueGetter: (p) => {
        if(!p.data) return null
        return parseFloat(p.data.post_payout_profit) || 0.00;

      },
      valueFormatter: (p) => {
        if(!p.data) return null
        return parseFloat(p.data.post_payout_profit).toFixed(2) || 0.00;

      },
    },
  ]}
]

</script>

<style lang="scss" scoped>
[v-cloak] {
  display: none;
}
.loader {
  z-index: 2;
}

.table {
  height: 70vh !important;
}

.subs-btn {
  height: 40px;
  padding: 0;
  background: #fff;
  border-color: #f6951e !important;
  color: #f6951e !important;

  &:hover {
    background: var(--c-grey-light) !important;
  }
}

@media screen and (max-width: 768px) {
  .reporting-filters {
    flex-direction: column;
  }

  .reporting-filters-container {
    flex-direction: column-reverse;
  }

  .report-input {
    width: 50%;

    &:last-child {
      margin-right: 0 !important;
    }
  }
}

::v-deep(.report-buttons) {
  @media screen and (max-width: 768px) {
    .btn {
      width: 50%;
    }
  }
}
</style>

<style lang="scss" >
@import "~ag-grid-community/styles/ag-grid.css";
@import "~ag-grid-community/styles/ag-theme-balham.min.css";
.reporting {
  .status-value {
    &.Failed {
      background: #ffe4e4;
      color: #b3322c;
    }

    &.Success {
      color: var(--c-success);
      background: #ddffef;
    }
  }

  .actions {
    height: 100% !important;
  }

  .action-btn {
    line-height: initial;
    width: 30px !important;
    height: 30px !important;
    padding: 2px 1px !important;
    font-size: 14px !important;
    border-radius: 50% !important;

    .icon-container {
      width: auto !important;

      svg {
        width: 19px !important;
      }
    }

    &.success {
      color: #fff !important;
    }
  }
}
</style>
