/**
 * @param {Object} input
 * @param {ReportTransaction} input.txData
 * @param {import('vue').Ref<boolean> | { value: boolean }} input.hasPermissions
 * @param {import('vue').Ref<boolean> | { value: boolean }} input.userHasAchRefundPermission
 * @param {import('vue').Ref<Set<string>> | { value: Set<string> }} input.userHasAchRefundPermission_to_merchants
 * @param {import('vue').Ref<boolean> | { value: boolean }} input.userHasCreditCardRefundPermission
 * @param {import('vue').Ref<Set<string>> | { value: Set<string> }} input.userHasCreditCardRefundPermission_to_merchants
 * @returns {boolean}
 */
export function shouldShowRefund({
  txData,
  hasPermissions,
  userHasAchRefundPermission,
  userHasAchRefundPermission_to_merchants,
  userHasCreditCardRefundPermission,
  userHasCreditCardRefundPermission_to_merchants
}) {
  // noinspection EqualityComparisonWithCoercionJS
  if (
    (txData?.type !== 'refund' &&
      txData?.type !== 'void' &&
      txData?.type !== 'batch' &&
      txData?.type !== 'credit' &&
      txData?.type !== 'authorize' &&
      txData?.request_status === true &&
      txData?.payment_status !== false) ||
    txData?.payment_status != 'false' ||
    txData?.payment_status != 'False'
  ) {
    if (hasPermissions.value) {
      if (
        txData?.type === 'debit' &&
        userHasAchRefundPermission.value &&
        userHasAchRefundPermission_to_merchants.value?.has(txData.client_id)
      ) {
        return true;
      }

      // noinspection RedundantIfStatementJS
      if (
        (txData?.type === 'credit' ||
          txData?.type === 'cc' ||
          txData?.type === 'authorize') &&
        userHasCreditCardRefundPermission.value &&
        userHasCreditCardRefundPermission_to_merchants.value?.has(
          txData.client_id
        )
      ) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }
  return false;
}
