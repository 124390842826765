<script setup>
import { onMounted, ref, watch } from 'vue';
import Modal from 'bootstrap/js/dist/modal';

// eslint-disable-next-line no-undef
const state = defineModel('state', {
  type: [Boolean, Object],
  default: false
})

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  isLarge: {
    type: Boolean,
    default: false,
  },
  // state: {
  //   type: [Object, Boolean],
  //   default: false,
  // },
});

let modalEle = ref(null);

/**
 * @type {null | import("bootstrap").Modal}
 */
let thisModalObj = null;

onMounted(() => {
  thisModalObj = new Modal(modalEle.value);
  if (props.state)
    thisModalObj.show();
});

/*
function _show() {
  thisModalObj.show();
}

defineExpose({ show: _show });
*/

watch(() => props.state, () => {
  if (state.value)
    thisModalObj?.show();
  else
    thisModalObj?.hide();
});
</script>

<template>
  <div
    id="exampleModal"
    ref="modalEle"
    class="modal fade"
    tabindex="-1"
    aria-labelledby=""
    aria-hidden="true"
  >
    <div class="modal-dialog" :style="props.isLarge ? 'min-width: 75vw;' : 'min-width: 400px;'">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="exampleModalLabel" class="modal-title">{{ title }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div v-if="props.state" class="modal-body">
          <slot />
          <!--
          <slot name="body" />
          -->
        </div>
        <div class="modal-footer">
          <!--<slot name="footer"></slot>-->
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="() => state = false">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
