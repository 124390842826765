<template>
  <div class="reporting management-page">
    <div class="title">Billing</div>

    <!-- Bootstrap Tabs -->
    <ul class="nav nav-tabs" id="billingTab" role="tablist">
      <li class="nav-item" role="presentation">
        <a class="nav-link active" id="bill-details-tab" data-bs-toggle="tab" href="#bill-details" role="tab" aria-controls="bill-details" aria-selected="true">Line Item</a>
      </li>
      <li class="nav-item" role="presentation">
        <a class="nav-link" id="rolling-billing-tab" data-bs-toggle="tab" href="#rolling-billing" role="tab" aria-controls="rolling-billing" aria-selected="false" @click="rollingBillingHack">Rolling Billing</a>
      </li>
    </ul>

    <div class="tab-content" id="billingTabContent">
      <div class="tab-pane fade show active" id="bill-details" role="tabpanel" aria-labelledby="bill-details-tab">
        <ag-grid
          ref="agRef"
          :define-defs="lineItemColDefs"
          group-panel=""
          :excel-options="{ fileName: 'LineItemBilling.xlsx' }"
          height="500px"
          :columns="[]"
          :side-bar="true"
        >
        </ag-grid>
      </div>

      <div class="tab-pane fade" id="rolling-billing" role="tabpanel" aria-labelledby="rolling-billing-tab">
        <ag-grid
          ref="agRefRolling"
          :apiurl="() => []"
          :define-defs="rollingColDefs"
          group-panel=""
          :excel-options="{ fileName: 'RollingBilling.xlsx' }"
          height="500px"
          :columns="[]"
          :side-bar="true"
          @first-data-rendered="onFirstDataRendered"
        >
        </ag-grid>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, onMounted, watch } from 'vue';
import AgGrid from '@/components/AgGrid.vue'; // Assuming this is your AgGrid component
import http from '@/services/http';

// const apiUrl = ref(`/api/billing`);

// Data variables
const agRef = ref();
const agRefRolling = ref();
const anotherAgRef = ref();
const billDetailsData = ref([]);
const rollingBillingData = ref([]);
watch([rollingBillingData, agRefRolling], () => {
  if (!rollingBillingData.value)
    return
  agRefRolling.value?.setTheRowData(rollingBillingData.value)
})
const billDetailsTotal = ref(0);
const rollingBillDetailsTotal = ref(0);

// Fetch the API response and fill the tables
onMounted(async () => {
  try {

    // call the api
    const response = await http.get(`/api/billing`);
    const message = response.data.message;

    // Populate the data for each table
    billDetailsData.value = message.bill_details;
    rollingBillingData.value = message.rolling_billing;

    // Update the totals
    const currentMonth = new Date().getMonth(); // Get the current month (0-based index)

    // Filter and sum the r_total values for the current month
    billDetailsTotal.value = message.bill_details.reduce((accumulator, current) => {
      const statementDate = new Date(current.statement_date);
      const statementMonth = statementDate.getMonth(); // Get the month from statement_date

      // Check if the statement is from the current month and add the r_total
      if (statementMonth === currentMonth) {
        return accumulator + (parseFloat(current.r_total).toFixed(2) || 0);
      }

      return accumulator;
    }, 0);

    rollingBillDetailsTotal.value = message.rolling_billing.reduce((accumulator, current) => {
      const statementDate = new Date(current.statement_date);
      const statementMonth = statementDate.getMonth(); // Get the month from statement_date

      // Check if the statement is from the current month and add the r_total
      if (statementMonth === currentMonth) {
        return accumulator + (parseFloat(current.total_billing).toFixed(2) || 0);
      }

      return accumulator;
    }, 0);

    // update first table
    agRef?.value?.setTheRowData(billDetailsData.value);  // Set the row data
    agRef?.value?.setTheColumnDefs(columnDefinitionsBillDetails);  // Update column definitions

    // update second table
    anotherAgRef?.value?.setTheRowData(rollingBillingData.value);  // Set the row data
    anotherAgRef?.value?.setTheColumnDefs(columnDefinitionsRollingBilling);  // Update column definitions

  } catch (error) {
    console.error('Failed to fetch data', error);
  }
});

// Define the column definitions for Bill Details table
const columnDefinitionsBillDetails = [
  { headerName: 'Name', field: 'name' },
  { headerName: 'Statement Date', field: 'statement_date' },
  { headerName: 'Description', field: 'detail_description' },
  // { headerName: 'Volume', field: 'volume', valueFormatter: params => parseFloat(params.value).toFixed(2) },
  { headerName: 'Billing', field: 'r_total', valueFormatter: params => parseFloat(params.value).toFixed(2) },
];

const lineItemColDefs = computed(() => {
  return columnDefinitionsBillDetails
});

// Define the column definitions for Rolling Billing table
const columnDefinitionsRollingBilling = [
  { headerName: 'Name', field: 'name' },
  { headerName: 'Processing Date', field: 'central_processing_date' },
  { headerName: 'CC Sales', field: 'cc_sales', valueFormatter: params => parseFloat(params.value).toFixed(2) },
  {
    headerName: 'Total Billing',
    field: 'total_billing',
    valueFormatter: params => parseFloat(params.value).toFixed(2),
  },
  // { headerName: 'Total', field: 'total', valueFormatter: params => parseFloat(params.value).toFixed(2) }
];

const rollingColDefs = computed(() => {
  return columnDefinitionsRollingBilling
});

// Grid ready function
const onFirstDataRendered = (params) => {
  // doesn't work anymore
  console.log('onFirstDataRendered');
  params.api.sizeColumnsToFit();
  params.api.refreshCells({ force: true });
  params.api.resetState();
};

let rollingBillingHackRan = false;
function rollingBillingHack() {
  if (rollingBillingHackRan) return
  setTimeout(() => agRefRolling.value.resetState(), 100)
  setTimeout(() => agRef.value.resetState(), 100)
  rollingBillingHackRan = true;
}
</script>

<style lang="scss" scoped>
/* General page layout */
.management-page {
  padding: 20px;
  background-color: #f8f9fa; /* Lighter background for a cleaner look */
}

.title {
  color: #343a40;
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 20px;
}

/* Tabs */
.nav-tabs {
  border-bottom: 2px solid #dee2e6;
}

.nav-item .nav-link {
  color: #495057;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 0 !important;
}

.nav-item .nav-link.active {
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
  font-weight: 600;
  color: #007bff;
}

/* Table Container */
.table {
  margin-top: 20px;
  height: 60vh; /* Reduce height to give more space */
  border: 1px solid #dee2e6;
  border-radius: 6px;
  background: #fff;
}

/* Totals Text */
.refresh-text {
  font-size: 18px;
  color: #495057;
  font-weight: 600;
  margin-top: 10px;
  display: inline-block;
}

/* Buttons */
.subs-btn {
  height: 40px;
  background: #007bff !important;
  border-color: #007bff !important;
  color: #fff !important;
  border-radius: 4px;
  transition: background 0.3s;

  &:hover {
    background: #0056b3 !important;
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .management-page {
    padding: 10px;
  }

  .title {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .nav-item .nav-link {
    font-size: 14px;
  }

  .table {
    height: 50vh;
  }
}

/* Custom styles for ag-grid */
.ag-theme-alpine {
  --ag-header-background-color: #f1f3f5;
  --ag-row-hover-color: #f8f9fa;
  --ag-header-cell-text-color: #343a40;
  --ag-odd-row-background-color: #fff;
  --ag-header-font-weight: 600;
}

</style>
