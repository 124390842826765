import { PERMISSION_PRIMITIVES } from '@/config/constants';

/**
 * handle RBAC behavior
 *
 * @param {import("vue").Ref<{id: string, name: string}>} computedMerchantFromApiKey
 * @param {import("vue").ComputedRef<boolean>} merchantHasPermissions
 * @param {import("vuex").Store<StoreState>} store
 * @param {Object} paramsObj
 * @param {import("vue-toastification").ToastInterface} toast
 * @param {import("vue-router").Router} router
 * @param {boolean} isSwipeOnly
 * @return {boolean} whether the user has permission
 */
export function processUserPermissions(computedMerchantFromApiKey, merchantHasPermissions, store, paramsObj, toast, router, isSwipeOnly) {
  if (merchantHasPermissions.value && !!store.state.user) {
    paramsObj.billingAddress = false;
    // const default_options = ['credit_card', 'bank', 'swipe'];
    const permissionSet = new Set(store.getters.grantedPermissions)
    console.log(
      'ALL PERMISSIONS FOR ROLE:',
      permissionSet
    );

    // eslint-disable-next-line no-inner-declarations
    function permOnThis(perm) {
      return store.getters.hasPermissionOnMerchant(perm, computedMerchantFromApiKey.value?.id);
    }

    if (isSwipeOnly) {
      if (permOnThis(PERMISSION_PRIMITIVES.TRANSACTION_SWIPE)) {
        paramsObj.paymentOptions = ['swipe'];
        return true;
      }

      paramsObj.paymentOptions = [];
      return false;
    }

    /*
     * These come in from the pre-configured virtual terminal options.
     * In order to render a payment option, all three must be true:
     *
     *  * It is not swipe only configuration (this only ever shows swipe)
     *  * The payment option is included in the originalPaymentOptions
     *  * The user has the relevant permission to view this option
     *
     */

    if (!permOnThis(PERMISSION_PRIMITIVES.VIRTUAL_TERMINAL)) {
      toast.error('User does not have VIRTUAL_TERMINAL permission.');

      return false;
    }

    const NEEDS = {
      [PERMISSION_PRIMITIVES.TRANSACTION_CHARGE]: 'credit_card',
      [PERMISSION_PRIMITIVES.TRANSACTION_DEBIT_ACH_CHARGE]: 'bank',
      [PERMISSION_PRIMITIVES.TRANSACTION_CASH]: 'cash',
      [PERMISSION_PRIMITIVES.TRANSACTION_SWIPE]: 'swipe',
      [PERMISSION_PRIMITIVES.TRANSACTION_APPLE_PAY]: 'apple_pay',
      [PERMISSION_PRIMITIVES.TRANSACTION_GOOGLE_PAY]: 'google_pay',
    };

    for (let key in NEEDS) {
      if (!permOnThis(key)) {
        paramsObj.paymentOptions = paramsObj.paymentOptions.filter(e => e !== NEEDS[key]);
      }
    }

    if (paramsObj.paymentOptions.length === 0) {
      toast.error(
        'User does not have permissions to use any payment methods.'
      );

      // router.push('/');
      return false;
    }

    if (permissionSet.has(PERMISSION_PRIMITIVES.OMIT_CC_ZIP)) {
      paramsObj.zipMode = null;
    }
  }

  return true;
}
