<template>
  <h3>Internal Reporting: {{ sourceName }}</h3>
  <ag-grid
    ref="agRef"
    :apiurl="apiUrl"
    :side-bar=true
    :agg-func="null"
    :define-defs="null"
    :excel-styles="[ { id: 'dateType', dataType: 'dateTime', numberFormat: { format: 'yyyy-mm-dd hh:mm:ss' } } ]"
    group-panel=''
    :excel-options="{fileName: `InternalReportingFor${sourceName}.xlsx`}"
    height="88%"
    @filter-changed="console.log"
  />
</template>
<script setup>
import { useRoute } from 'vue-router';
import { computed, ref, watch } from 'vue';
import AgGrid from '@/components/AgGrid.vue';

const route = useRoute();

const agRef = ref();

const sourceName = computed(() => route.params.sourceName);
const apiUrl = computed(() => `/api/v1/report/all-internal/${sourceName.value}`);

// watch for source name changes
sourceNameChanged(sourceName.value);
watch(() => sourceName.value, () => {
  sourceNameChanged(sourceName.value);
});


function sourceNameChanged(sourceName) {
  console.log('AllInternalDataReportingPage: sourceName', sourceName, arguments);
  agRef.value?.getAPI();
}

</script>
