<template>
  <div class="terminals-page-css management-page">
    <simple-modal v-model:state="terminalBeingLocationAssigned" :is-large="true" title="Assign Terminal Location">
      <h3>Assigning Location for Lane {{ terminalBeingLocationAssigned.lane_id }}</h3>

      <!--<p>Current value: {{ terminalBeingLocationAssigned.sub_entity }}</p>-->

      <div ref="selectTerminalLocationContainer">
        <!--v-model="terminalBeingLocationAssigned.sub_entity.id"-->
        <Select2
          v-if="selectTerminalLocationContainer"
          ref="selectTerminalLocationSelect2"
          :options="terminalAvailableLocationsSelect2"
          :settings="{ dropdownParent: selectTerminalLocationContainer, allowClear: true }"
          style="width: 400px;"
          :model-value="{
            id: terminalBeingLocationAssigned.sub_entity.id,
            label: terminalBeingLocationAssigned.sub_entity.name,
          }"
          @update:model-value="({id, label}) =>{
            terminalBeingLocationAssigned.sub_entity.name = label;
            terminalBeingLocationAssigned.sub_entity.id = id;
          }"
        >
          <!--
          class="form-select"
          <option :value="null" disabled>Select an Account</option>
          <option
            v-for="loc in terminalAvailableLocations"
            :key="loc.id"
            :value="loc.id"
          >
            {{ loc.name }} ({{ loc.merchant.name }})
          </option>
          -->
        </Select2>
      </div>

      <base-button
        :disabled="terminalLocationAssignedLoading"
        @click="terminalAssignLocation"
      >
        Save
      </base-button>
      <!--<base-button @click="terminalBeingLocationAssigned.value = null">Close</base-button>-->
    </simple-modal>
    <!--
    <view-report-modal v-model="showViewModal" :details="showViewDetails" />
    <base-modal v-model="showRefundModal">
      <refund-modal
        :transaction="transactionDetails"
        @update="handleRefundSuccess"
      />
    </base-modal>
    -->

    <div class="title" style="color: #383838; font-size: 24px">
      Terminal Settings
    </div>
    <div class="mt-3 mb-2 position-relative">
      <!-- Add margin to separate from elements above and below -->
    </div>
    <!--
    -->
    <base-spinner v-if="loadingList" class="loader" />
    <AgGrid
      ref="agRef"
      :apiurl="apiUrl"
      :columns="[]"
      :define-defs="columnDefs"
      :side-bar="true"
      :status-bar="{}"
      :agg-func="undefined/*aggFunc*/"
      :excel-styles="excelStyles"
      group-panel=""
      :counter="false"
      total-column-name=""
      :excel-options="{ fileName: 'Terminal Report.xlsx' }"
      height="84vh"
      :get-api-data-on-ready-enabled="false"
    >
    </AgGrid>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import AgGrid from '@/components/AgGrid.vue';
import BaseSpinner from '@/components/base/BaseSpinner.vue';
import { useStore } from 'vuex';
import { PERMISSION_PRIMITIVES } from '@/config/constants';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import BaseButton from '@/components/base/BaseButton.vue';
import http from '@/services/http';
import { computedAsync } from '@vueuse/core';
import SimpleModal from '@/components/base/SimpleModal.vue';

const agRef = ref(null);
const selectTerminalLocationContainer = ref(null);
const selectTerminalLocationSelect2 = ref(null);
const store = useStore();
const router = useRouter();
const toast = useToast();
const user = computed(() => !!store.state.user);
const hasPermissions = computed(() => store.getters.merchantHasPermissions);
const userHasTerminalAdmin = computed(() => store.getters.grantedPermissions.includes(PERMISSION_PRIMITIVES.TERMINAL_ADMIN));

watch([user, userHasTerminalAdmin], () => {
  if (user.value && (hasPermissions.value && !userHasTerminalAdmin.value)) {
    toast.error('User does not terminal permission');
    router.push('/');
  }
});

/**
 * @typedef {{
 *   id: string
 *   name: string
 *   merchant: {
 *     id: string
 *     name: string
 *   }
 * }} TerminalV1SubEntityModel
 */

/**
 * @typedef {{
 *   id: string
 *   sn: string
 *   tpn: string
 *   lane_id: string
 *   register_id: string
 *   is_active: Boolean
 *   terminal_type: 'Dejavoo' | ''
 *   terminal_ip_address?: string
 *   client: {
 *     merchant: {
 *       id: string
 *       name: string
 *     }
 *   }
 *   sub_entity?: TerminalV1SubEntityModel
 * }} TerminalV1Model
 */

const apiUrl = ref('/api/v1/terminals');
const loadingList = ref(false);

const excelStyles = [
  {
    id: 'dateType',
    dataType: 'dateTime',
    numberFormat: { format: 'yyyy-mm-dd hh:mm:ss' },
  },
];

/**
 * @typedef {import('ag-grid-community').ColDef | import('ag-grid-community').ColGroupDef} AgColumn
 */

const TERMINAL_V1_MODEL_FIELDS = [
  // { field: 'id', column: 'ID' },
  { field: 'sn', column: 'Serial Number' },
  { field: 'tpn', column: 'Terminal TPN' },
  { field: 'lane_id', column: 'Lane ID' },
  { field: 'register_id', column: 'Register ID' },
  { field: 'is_active', column: 'Is Active' },
  { field: 'terminal_type', column: 'Type' },
  { field: 'terminal_ip_address', column: 'IP Address' },
];

function initTerminalModelForEditing(value) {
  // noinspection JSValidateTypes
  value.client = value.client || {};
  // noinspection JSValidateTypes
  value.client.merchant = value.client.merchant || {};
  // noinspection JSValidateTypes
  value.sub_entity = value.sub_entity || {};
  // noinspection JSValidateTypes
  value.sub_entity.merchant = value.sub_entity.merchant || {};
  return value;
}

/**
 * @type {import('vue').Ref<Array<AgColumn>>}
 */
// noinspection JSUnusedGlobalSymbols
const columnDefs = computed(() => [
  {
    headerName: `Terminal Details`,
    children: [
      {
        headerName: 'ID',
        filter: 'agTextColumnFilter',
        /**
         * @param {{data: TerminalV1Model}} p
         */
        valueGetter: (p) => {
          if (!p.data) return '';
          return p.data?.id;
        },
      },
      ...TERMINAL_V1_MODEL_FIELDS.map(({ field, column }) => ({
        headerName: column,
        filter: 'agTextColumnFilter',
        valueGetter: p => (p.data?.[field] || ''),
      })),
      {
        headerName: 'Location',
        filter: 'agTextColumnFilter',
        /**
         * @param {{data: TerminalV1Model}} p
         */
        valueGetter: (p) => `${p.data?.sub_entity?.name} (${p.data?.sub_entity?.merchant?.name})`,
      },
    ],
  },
  {
    headerName: 'Actions',
    children: [
      {
        headerName: 'Assign Location',
        filter: false,
        field: 'view',
        cellRenderer: () => `<p style="font-size: 12px; text-decoration: underline;">Open</p>`,
        onCellClicked: function(params) {
          console.log('onCellClicked');
          /**
           * @type {TerminalV1Model}
           */
          let value = params.node.data;
          terminalBeingLocationAssigned.value = initTerminalModelForEditing(value);
        },
      },
      /*
      {
        headerName: 'Refund',
        filter: false,
        field: 'refund',
        hide: hasPermissions.value && !userHasRefundPermission.value,
        /!**
         * @param {{data: ReportingTuple}} p
         * @returns {string}
         *!/
        cellRenderer: function(p) {
          const returnLink = `<p style="font-size: 12px; text-decoration: underline;">Refund</p>`;
          if (!p.data) return '';
          const txData = p.data[1];
          // noinspection EqualityComparisonWithCoercionJS
          if (
            (txData?.type !== 'refund' &&
              txData?.type !== 'void' &&
              txData?.type !== 'batch' &&
              txData?.type !== 'credit' &&
              txData?.type !== 'authorize' &&
              txData?.request_status === true &&
              txData?.payment_status !== false) ||
            txData?.payment_status != 'false' ||
            txData?.payment_status != 'False'
          ) {
            if (hasPermissions.value) {
              if (
                txData?.type === 'debit' &&
                userHasAchRefundPermission.value &&
                userHasAchRefundPermission_to_merchants.value?.has(txData.client_id)
              ) {
                return returnLink;
              } else if (
                (txData?.type === 'credit' ||
                  txData?.type === 'cc' ||
                  txData?.type === 'authorize') &&
                userHasCreditCardRefundPermission.value &&
                userHasCreditCardRefundPermission_to_merchants.value?.has(txData.client_id)
              ) {
                return returnLink;
              } else {
                return '';
              }
            }
            return returnLink;
          }
          return '';
        },
        onCellClicked: function(p) {
          if (!p.data) return '';
          const txData = p.data[1];
          // noinspection EqualityComparisonWithCoercionJS
          if (
            (txData?.type !== 'refund' &&
              txData?.type !== 'void' &&
              txData?.type !== 'batch' &&
              txData?.type !== 'credit' &&
              txData?.type !== 'authorize' &&
              txData?.request_status === true &&
              txData?.payment_status === true) ||
            txData?.payment_status == 'true' ||
            (txData?.payment_status == 'True' && Number(txData?.amount))
          ) {
            if (hasPermissions.value) {
              if (
                txData?.type === 'debit' &&
                userHasAchRefundPermission.value &&
                userHasAchRefundPermission_to_merchants.value?.has(txData.client_id)
              ) {
                transactionDetails.value = txData;
                showRefundModal.value = true;
              } else if (
                (txData?.type === 'credit' || txData.type === 'cc') &&
                userHasCreditCardRefundPermission.value &&
                userHasCreditCardRefundPermission_to_merchants.value?.has(txData.client_id)
              ) {
                transactionDetails.value = txData;
                showRefundModal.value = true;
              }
            } else {
              transactionDetails.value = txData;
              showRefundModal.value = true;
            }
          }
        }
      }
      */
    ],
  },
]);

/**
 * @type {import('vue').Ref<null | TerminalV1Model>}
 */
const terminalBeingLocationAssigned = ref(null);

watch([() => terminalBeingLocationAssigned.value, () => selectTerminalLocationSelect2.value], () => {
  /*
  console.log('changed terminal or select2Ref', {
    terminalBeingLocationAssigned: terminalBeingLocationAssigned.value,
    selectTerminalLocationSelect2: selectTerminalLocationSelect2.value,
  });
  */
  if (!selectTerminalLocationSelect2.value)
    return;
  let ref = selectTerminalLocationSelect2.value;
  setTimeout(() => {
    let select = ref?.['select2'];
    select?.trigger('change');
    select?.select2({
      width: '100%',
      dropdownParent: selectTerminalLocationContainer.value,
    });
  }, 100);
});

/**
 * @type {import('vue').Ref<Array<TerminalV1SubEntityModel>>}
 */
const terminalAvailableLocations = computedAsync(async () => {
  let response = await http.get(`/api/rbac/${store.state.user.account.default_client.id}/sub-entities`);
  return response.data.message;
}, []);
const terminalLocationAssignedLoading = ref(false);

const terminalAvailableLocationsSelect2 = computed(() => {
  return terminalAvailableLocations.value.map(e => ({ id: e.id, label: `${e.name} (${e.merchant.name})` }));
});

async function terminalAssignLocation() {
  terminalLocationAssignedLoading.value = true;
  try {
    let response = await http.patch(
      `/api/v1/terminals/${terminalBeingLocationAssigned.value.id}`,
      {
        sub_entity: {
          id: terminalBeingLocationAssigned.value.sub_entity.id,
        },
      },
      {
        headers: { 'content-type': 'application/json' },
      },
    );
    Object.assign(terminalBeingLocationAssigned.value, response.data);
    loadingList.value = true;
    await new Promise(r => agRef.value?.refreshAPI(apiUrl.value, r));
  } finally {
    loadingList.value = false;
    terminalLocationAssignedLoading.value = false;
  }
}

</script>

<style lang="scss" scoped>
[v-cloak] {
  display: none;
}

.loader {
  z-index: 2;
}

.table {
  height: 70vh !important;
}

.subs-btn {
  height: 40px;
  padding: 0;
  background: #fff;
  border-color: #f6951e !important;
  color: #f6951e !important;

  &:hover {
    background: var(--c-grey-light) !important;
  }
}

@media screen and (max-width: 768px) {
  .reporting-filters {
    flex-direction: column;
  }

  .reporting-filters-container {
    flex-direction: column-reverse;
  }

  .report-input {
    width: 50%;

    &:last-child {
      margin-right: 0 !important;
    }
  }
}

::v-deep(.report-buttons) {
  @media screen and (max-width: 768px) {
    .btn {
      width: 50%;
    }
  }
}
</style>

<style lang="scss">
@import '~ag-grid-community/styles/ag-grid.css';
@import '~ag-grid-community/styles/ag-theme-balham.min.css';

.terminals-page-css {
  .status-value {
    &.Failed {
      background: #ffe4e4;
      color: #b3322c;
    }

    &.Success {
      color: var(--c-success);
      background: #ddffef;
    }
  }

  .actions {
    height: 100% !important;
  }

  .action-btn {
    line-height: initial;
    width: 30px !important;
    height: 30px !important;
    padding: 2px 1px !important;
    font-size: 14px !important;
    border-radius: 50% !important;

    .icon-container {
      width: auto !important;

      svg {
        width: 19px !important;
      }
    }

    &.success {
      color: #fff !important;
    }
  }
}
</style>
