<template>
  <div class="management-page new-link-page w-100 px-2">
    <base-modal v-model="showSourceCodeModal">
      <source-code :value="sourceCodeValue" />
    </base-modal>

    <div class="header">
      <div v-if="logo" class="logo mb-3">
        <img :src="logo" alt="" />
      </div>
      <h3 class="title">
        <span> Payment Set-Up </span>
        <span v-if="clientDetails && clientDetails.name">
          for {{ clientDetails.name }}
        </span>
      </h3>

      <p class="fz-12">
        This easy payment functionality utilizes your unique API key and allows
        you to create payment and subscription pages to send or provide on the
        spot as invoices or bills.
      </p>
    </div>

    <form class="mt-3 form" @submit.prevent>
      <page-design
        v-model="pageDesign"
        :reset-styles="resetStyles"
        :reset-component-styles="resetComponentStyles"
        :set-component-styles="setComponentStyles"
      />

      <p class="fz-20 color-dark fw-700 mt-3 mb-2">Payment</p>

      <div class="d-flex">
        <div
          class="choose-btn w-100 mr-2"
          :class="{ 'choose-btn_active': paymentType === PAYMENT_TYPES.CHARGE }"
          @click="changePaymentType(PAYMENT_TYPES.CHARGE)"
        >
          One-Time Charge
        </div>

        <div
          class="choose-btn w-100"
          :class="{
            'choose-btn_active': paymentType === PAYMENT_TYPES.SUBSCRIPTION
          }"
          @click="changePaymentType(PAYMENT_TYPES.SUBSCRIPTION)"
        >
          Subscription (Recurring Charge)
        </div>
      </div>

      <transition name="slide-y">
        <div
          v-if="paymentType === PAYMENT_TYPES.SUBSCRIPTION"
          class="d-flex align-items-center mt-3"
        >
          <div v-if="paymentType === PAYMENT_TYPES.SUBSCRIPTION" class="mt-1">
            <p>Provide a:</p>
            <div class="d-flex">
              <div class="d-flex align-items-center">
                <input
                  id="trialPeriodRadio"
                  v-model="showTrialPeriod"
                  type="radio"
                  value="trial"
                  @change="handleChangeSubscriptionOptions"
                />
                <label for="trialPeriodRadio" class="ml-1">Trial Period</label>
              </div>

              <p class="ml-2"><strong>or</strong></p>

              <div class="d-flex align-items-center ml-3">
                <input
                  id="startDateRadio"
                  v-model="showTrialPeriod"
                  type="radio"
                  value="start"
                  @change="handleChangeSubscriptionOptions"
                />
                <label for="startDateRadio" class="ml-1">Start Date</label>
              </div>
            </div>

            <template v-if="showTrialPeriod === 'trial'">
              <div class="d-flex align-items-center">
                <span>Start after</span>
                <base-input
                  v-model="trialPeriod"
                  placeholder="0"
                  style="width: 55px"
                  class="default-input trial-period ml-1"
                  :error="trialPeriodError"
                  :min="0"
                  type="number"
                  @blur="validateTrialPeriod"
                  @input="handleChangeSubscriptionOptions"
                ></base-input>
                <span class="ml-2">Days</span>
              </div>
            </template>

            <template v-else-if="showTrialPeriod === 'start'">
              <span>Start on </span>
              <base-input
                v-model="startDate"
                placeholder="Start Date"
                style="width: 150px"
                class="default-input start-date ml-1"
                type="date"
                @input="handleChangeSubscriptionOptions"
              ></base-input>
            </template>
          </div>
        </div>
      </transition>

      <div class="d-flex">
        <p
          v-if="paymentType === PAYMENT_TYPES.SUBSCRIPTION"
          class="fw-400 mr-5 color-dark label mt-4"
        >
          Charge this amount:
        </p>
        <p
          v-if="paymentType === PAYMENT_TYPES.CHARGE"
          class="fw-400 mr-5 color-dark label mt-4"
        >
          Amount:
        </p>

        <base-input
          v-model="amount"
          placeholder="10"
          class="default-input mt-3"
          type="number"
          :error="amountError"
          step=".01"
          :min="0"
          pattern="^\d*(\.\d{0,2})?$"
          @blur="validateAmount"
        >
        </base-input>
      </div>

      <transition name="slide-y">
        <div
          v-if="paymentType === PAYMENT_TYPES.SUBSCRIPTION"
          class="subscription-data mt-2"
        >
          <p>Charge it at this frequency:</p>

          <div class="d-flex mt-2 align-items-baseline">
            <base-dropdown
              v-model="interval"
              :options="INTERVAL_OPTIONS"
              placeholder="Interval"
              class="default-input w-70 mr-2"
              type="number"
              :error="intervalError"
              @blur="validateInterval"
              @update:modelValue="handleChangeSubscriptionOptions"
            />
          </div>

          <div class="d-flex mt-2">
            <base-input
              v-model="cycles"
              placeholder="Cycles"
              label="How many times total? Leave blank to recur until cancelled."
              class="default-input w-100 mr-2"
              type="number"
              :error="cyclesError"
              :min="1"
              @blur="validateCycles"
              @update:modelValue="handleChangeSubscriptionOptions"
            >
            </base-input>
          </div>
        </div>
      </transition>

      <hr class="hr mt-3 mb-3" />

      <div
        class="fw-normal color-grey-medium fz-14 mb-0 mt-3 d-flex align-items-center"
      >
        <p class="fz-20 color-dark fw-700">Payment Methods</p>
      </div>

      <div class="options-container">
        <base-spinner v-if="loadingAvailablePaymentOptions" class="loader" />

        <p
          v-if="
            !filteredPaymentOptions.length && !loadingAvailablePaymentOptions
          "
          class="text-center fz-14 color-grey-medium pt-2"
        >
          No payment methods available
        </p>

        <div class="d-flex flex-column mt-2">
          <div>
            <base-checkbox
              v-for="paymentOption in filteredPaymentOptions"
              :key="paymentOption.value"
              v-model="paymentOptions"
              class="w-100 checkbox"
              :disabled="
                paymentOption.disabled &&
                paymentOption.disabled({ paymentType })
              "
              :label="paymentOption.name"
              :value="paymentOption.value"
            >
              <template #label>
                <div class="d-flex align-items-baseline">
                  <p class="form-check-label fz-14 color-dark">
                    {{ paymentOption.name }}
                  </p>

                  <base-tooltip
                    v-if="
                      paymentType === PAYMENT_TYPES.SUBSCRIPTION &&
                      paymentOption.disabled &&
                      paymentOption.disabled({ paymentType })
                    "
                    title="Not available for subscriptions."
                    class="color-dark cursor-pointer question ml-2"
                    placement="right"
                    :multiple-lines="false"
                  >
                    <help-circle-outline :size="16" />
                  </base-tooltip>

                  <base-tooltip
                    v-else-if="paymentOption.tooltip"
                    :title="paymentOption.tooltip"
                    class="color-dark cursor-pointer question ml-2"
                    placement="right"
                    :multiple-lines="false"
                  >
                    <help-circle-outline :size="16" />
                  </base-tooltip>
                </div>
              </template>
            </base-checkbox>
          </div>
        </div>
      </div>
      <transition name="slide-y">
        <p
          v-if="paymentOptionsError"
          class="mt-1 color-danger fz-14 pl-2 mt-1 fw-bolder"
        >
          {{ paymentOptionsError }}
        </p>
      </transition>

      <div
        v-if="loadingTerminals"
        class="position-relative terminals-loader-container"
      >
        <base-spinner class="loader" />
      </div>

      <div v-if="!loadingTerminals">
        <transition name="slide-y">
          <div
            v-if="paymentOptions.includes('terminal') && terminals.length"
            class="pt-2 w-100"
          >
            <base-dropdown
              v-model="selectedTerminal"
              :options="terminals"
              placeholder="Choose terminal"
              label="Terminal"
              class="default-input w-100"
            />
          </div>
          <div
            v-else-if="paymentOptions.includes('terminal') && !terminals.length"
          >
            <p class="fz-14 fw-500 color-grey-medium pt-2">
              No available terminals
            </p>
          </div>
        </transition>
      </div>

      <hr class="hr mt-3 mb-3" />

      <div class="mt-3">
        <p class="fz-20 color-dark fw-700">Input Fields</p>

        <ask-option
            v-if="levelThreeDataIsAvailable"
            v-model="levelThreeDataIsEnabled"
            label="Capture Level 3 Data"
            class="mt-3" />

        <ask-option v-model="showFullName" label="Full name" class="mt-3" />

        <!-- tooltip="Customer will need to provide shipping address." -->
        <ask-option
          v-model="showShippingAddress"
          label="Shipping address"
          class="mt-3"
        />

        <ask-option
          v-model="showBillingAddress"
          label="Billing address"
          class="mt-3"
        />

        <ask-option
          v-model="emailAddressOption"
          :options="WITH_OPTIONAL"
          :disabled="disabledEmailOptions"
          class="mt-3"
        >
          <template #label>
            <div class="d-flex ask-default-label align-items-center mr-2">
              <p class="fz-14 color-dark mr-2">Email address</p>

              <base-tooltip
                title='Please select "Optional" or "Required" if customer is given the option to save payments or opt in to emails, and "Required" for recurring charges.'
                class="color-dark cursor-pointer question"
              >
                <help-circle-outline :size="16" />
              </base-tooltip>
            </div>
          </template>
        </ask-option>

        <ask-option
          v-model="phoneOption"
          :options="WITH_OPTIONAL"
          label="Phone number"
          class="mt-3"
        />
      </div>

      <hr class="hr mt-3 mb-3" />

      <div class="mt-3">
        <p class="fz-20 color-dark fw-700">Additional Options</p>
<!--        <ask-option v-model="saveMode" class="mt-3 ask-option-wide">-->
<!--          <template #label>-->
<!--            <div class="ask-custom-label mr-2">-->
<!--              <p class="fz-14 color-dark">Save-Mode only?</p>-->

<!--              <p class="fz-12 color-dark">-->
<!--                Configures this link to only allow saving a card or bank payment-->
<!--                method. No payments will be processed through this-->
<!--                configuration.-->
<!--              </p>-->
<!--            </div>-->
<!--          </template>-->
<!--        </ask-option>-->
<!--        <ask-option-->
<!--          v-model="allowCustomerToSaveCreditCards"-->
<!--          class="mt-3 ask-option-wide"-->
<!--          @update:modelValue="moveEmailOptionTo($event, 'optional', false)"-->
<!--        >-->
<!--          <template #label>-->
<!--            <div class="ask-custom-label mr-2">-->
<!--              <p class="fz-14 color-dark">-->
<!--                Allow customer to save payment details for future purchases?-->
<!--              </p>-->

<!--              <p class="fz-12 color-dark">-->
<!--                (Option only shows if paying by card.)-->
<!--              </p>-->
<!--            </div>-->
<!--          </template>-->
<!--        </ask-option>-->

<!--        <ask-option-->
<!--          v-model="showEmailOptIn"-->
<!--          label="Provide email opt-in?"-->
<!--          class="mt-3 ask-option-wide"-->
<!--          @update:modelValue="moveEmailOptionTo($event, 'optional', false)"-->
<!--        />-->
        <ask-option
          v-model="allowTip"
          label="Allow tip?"
          class="mt-3 ask-option-wide"
        />
        <ask-option
          v-model="showDescription"
          label="Include description?"
          class="mt-3 ask-option-wide"
        />
      </div>

      <!-- <transition name="slide-y">
        <ask-option
          v-if="
            paymentOptions.includes('credit_card') &&
            paymentType !== PAYMENT_TYPES.SUBSCRIPTION
          "
          v-model="savePaymentToken"
          label="Save customer credit cards"
          tooltip="User will be able to reuse saved credit cards."
          class="mt-3"
        />
      </transition> -->
      <!-- <transition name="slide-y">
        <ask-option
          v-if="paymentOptions.includes('credit_card')"
          v-model="zipMode"
          label="Zip code option"
          tooltip="Zip code can be required field for credit card payments or disabled(and hidden)."
          class="mt-3"
          :options="ZIP_CODE_OPTIONS"
        />
      </transition> -->

      <!-- <transition name="slide-y">
        <div v-if="paymentType !== PAYMENT_TYPES.SUBSCRIPTION">
          <ask-option
            v-model="allowTip"
            label="Allow tip"
            tooltip="Tip section will be displayed for customers."
            class="mt-3"
          />
        </div>
      </transition> -->

      <!-- <p class="fw-normal color-grey-medium fz-14 mb-0 mt-3">Description</p> -->
      <div v-if="showDescription" class="pb-3 mt-3">
        <description-section
          v-model="description"
          :description-error="descriptionError"
          :saved-description-value="savedDescriptionValue"
          :clear-description="clearDescription"
        />
      </div>

      <hr class="hr mt-4 mb-4" />

      <div class="d-flex mt-3 justify-content-center">
        <base-button
          v-if="!createLinkButtonDisabled"
          id="linkBtn"
          :disabled="loadingPublishableKey || publishableKeyError"
          class="mr-2 generate-btn"
          style="width: 200px"
          @click="generateLink"
        >
          <span>
            <link-icon />
          </span>
          <span>{{ linkBtnText }}</span>
        </base-button>

        <base-button
          id="pageBtn"
          class=""
          :disabled="loadingPublishableKey || publishableKeyError"
          style="width: 300px"
          @click="openInNewWindow"
        >
          <span>
            <cash />
          </span>
          <span>{{ pageBtnText }}</span>
        </base-button>
      </div>

      <div id="accordionFlushExample" class="accordion accordion-flush mt-5">
        <div class="accordion-item">
          <h2 id="flush-headingOne" class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseOne"
              aria-expanded="false"
              aria-controls="flush-collapseOne"
            >
              Developer Options
            </button>
          </h2>
          <div
            id="flush-collapseOne"
            class="accordion-collapse collapse"
            aria-labelledby="flush-headingOne"
            data-bs-parent="#accordionFlushExample"
          >
            <div class="accordion-body">
              <div class="mt-1">
                <div class="form-check">
                  <input
                    id="sandbox-mode-checkbox"
                    v-model="sandboxMode"
                    class="form-check-input"
                    type="checkbox"
                  />
                  <label
                    class="form-check-label ms-1"
                    for="sandbox-mode-checkbox"
                  >
                    This is a test transaction
                  </label>
                </div>
                <small
                  >*Test transactions do not process as real transactions. They
                  will "process" through the developer Sandbox and no real money
                  will be transacted. Test transactions and the Sanbox
                  environment are for simulation purposes.</small
                >
              </div>
              <hr />
              <div class="mt-1">
                <p>
                  Copy this code to your iframe set-up:
                  <a href="#" @click.prevent="getSourceCode"> view it here. </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios';

import BaseInput from '@/components/base/BaseInput.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import BaseModal from '@/components/base/BaseModal.vue';
import BaseSpinner from '@/components/base/BaseSpinner.vue';
import BaseCheckbox from '@/components/base/BaseCheckbox.vue';
import DescriptionSection from '@/components/general/DescriptionSection.vue';
import LinkIcon from 'vue-material-design-icons/Link.vue';
import Cash from 'vue-material-design-icons/Cash.vue';
import PageDesign from '@/components/links/PageDesign.vue';
import SourceCode from '@/components/links/SourceCode.vue';

import useToast from '@/composables/common/toast';
import useValidation from '@/composables/new-link/validation.js';
import useSubscriptionFrequency from '@/composables/new-link/subscriptionFrequency.js';
import { computed, ref } from 'vue';
import {
  PAYMENT_OPTIONS_INTERFACE,
  PAYMENT_TYPES,
  INTERVAL_OPTIONS,
  API_KEY_LS_NAME,
  PAYMENT_OPTIONS,
  CRYPTO_TYPES,
  ZIP_CODE_OPTIONS,
  SOURCE,
  REPLACE_PERCENT_VALUE
} from '@/config/constants.js';
import HelpCircleOutline from 'vue-material-design-icons/HelpCircleOutline.vue';
import BaseTooltip from '@/components/base/BaseTooltip.vue';
import BaseDropdown from '@/components/base/BaseDropdown.vue';
import AskOption from '@/components/links/AskOption.vue';
import useService from '@/composables/common/services';
import { onMounted } from 'vue';
import useTerminals from '@/composables/new-link/terminals.js';
import useAdditionalOptions from '@/composables/new-link/additionalOptions.js';
// import usePageDesign from '@/composables/new-link/pageDesign.js';
import usePageDesign from '@/composables/new-link/usePageDesign';
import useGetCode from '@/composables/new-link/getCode.js';
import useEmailStates from '@/composables/new-link/emailStates.js';
import { toRefs } from 'vue';
const { DateTime } = require('luxon');
import { paymentMethodAvailable } from '@/helpers/index';

const SAVED_DESCRIPTION_KEY = 'saved-link-description';
const SAVED_PAYMENT_OPTIONS_KEY = 'saved-payment-options';

const WITH_OPTIONAL = [
  { text: 'Off', value: false },
  { text: 'Optional', value: 'optional' },
  { text: 'Required', value: 'required' }
];

export default {
  name: 'NewLink',
  components: {
    BaseInput,
    BaseButton,
    HelpCircleOutline,
    BaseTooltip,
    BaseDropdown,
    BaseCheckbox,
    BaseSpinner,
    DescriptionSection,
    AskOption,
    LinkIcon,
    Cash,
    PageDesign,
    SourceCode,
    BaseModal
  },
  props: {
    apikey: {
      type: String,
      default: null
    }
  },
  emits: ['success'],
  setup(props, { emit }) {
    const { apikey } = toRefs(props);

    const lsDescrValue = localStorage.getItem(SAVED_DESCRIPTION_KEY);
    const savedDescriptionValue = ref({});

    if (lsDescrValue && lsDescrValue.trim() && lsDescrValue !== 'undefined') {
      savedDescriptionValue.value = JSON.parse(lsDescrValue);
    }

    const savedPaymentOptions = JSON.parse(
      localStorage.getItem(SAVED_PAYMENT_OPTIONS_KEY) || '[]'
    );

    const {
      // subscription
      showTrialPeriod,
      trialPeriod,
      trialPeriodError,
      validateTrialPeriod,
      // afterTrialStartDate,
      startDate,
      initialStartDate,
      endDate,
      startDateError,
      validateStartDate,
      endDateError,
      validateEndDate,
      cycles,
      cyclesError,
      validateCycles,
      frequency,
      frequencyError,
      validateFrequency,
      interval,
      intervalError,
      validateInterval,

      title,
      amount,
      description,
      titleError,
      amountError,
      descriptionError,
      validateTitle,
      validateAmount,
      validateDescription,
      validate,

      // storedApiKey,
      // apiKey,
      // apiKeyError,
      // validateApiKey,

      // showApiKey,

      paymentType,

      email,
      emailError,
      validateEmail,

      paymentOptions,
      paymentOptionsError,

      allowTip,

      showEmail,

      zipMode
    } = useValidation({
      paymentOptions: savedPaymentOptions,
      description: savedDescriptionValue.value
    });

    if (savedPaymentOptions && savedPaymentOptions.length) {
      paymentOptions.value = savedPaymentOptions;
    }

    const clearDescription = () => {
      savedDescriptionValue.value = null;
      localStorage.removeItem(SAVED_DESCRIPTION_KEY);
    };

    if (
      savedDescriptionValue.value &&
      Object.keys(savedDescriptionValue.value).length > 0
    ) {
      description.value = savedDescriptionValue.value;
    }
    const { showMessage } = useToast();

    // Add sandboxMode ref for checkbox value
    const sandboxMode = ref(false);
    const levelThreeDataIsEnabled = ref(false);
    const levelThreeDataIsAvailable = ref(false);


    const linkBtnText = computed(() => {
      const text = ' Get Link';
      return sandboxMode.value ? `${text} TEST` : text;
    });
    const pageBtnText = computed(() => {
      const text = ' Open Payment Page';
      return sandboxMode.value ? `${text} TEST` : text;
    });

    const {
      showFullName,
      showBillingAddress,
      showDescription,
      emailAddressOption,
      phoneOption,
      showEmailOptIn,
      allowCustomerToSaveCreditCards,
      showShippingAddress,
      saveMode
    } = useAdditionalOptions();

    const { disabledEmailOptions, moveEmailOptionTo, handlePaymentTypeChange } =
      useEmailStates({
        emailAddressOption
      });

    const { fetchTerminals, terminals, selectedTerminal, loadingTerminals } =
      useTerminals(apikey);

    const { getSubscriptionDate } = useSubscriptionFrequency({
      amount,
      frequency,
      interval,
      startDate,
      trialPeriod
    });

    const loadingAvailablePaymentOptions = ref(false);
    const loadingPublishableKey = ref(false);
    const publishableKeyError = ref(false);
    const publishableKey = ref(null);
    const clientDetails = ref(null);
    const {
      pageDesign,
      generateStylesObjectForIFrame,
      resetStyles,
      resetComponentStyles,
      setComponentStyles
    } = usePageDesign();

    const { apiService } = useService();
    const { showSourceCodeModal, sourceCodeValue, handleShowSourceCode } =
      useGetCode();
    const availablePaymentOptions = ref({
      crypto: false,
      credit_card: false,
      bank: false
      // digital: false
    });

    // const lsDescrValue = localStorage.getItem(SAVED_DESCRIPTION_KEY);
    // const savedDescriptionValue = ref({});

    // if (lsDescrValue && lsDescrValue.trim() && lsDescrValue !== 'undefined') {
    //   savedDescriptionValue.value = JSON.parse(lsDescrValue);
    // }

    // const savedPaymentOptions = JSON.parse(
    //   localStorage.getItem(SAVED_PAYMENT_OPTIONS_KEY) || '[]'
    // );

    // const showFrequencyText = computed(() => {
    //   return amount.value && frequency.value && interval.value && startDate.value && trialPeriod.value;
    // });

    const createLinkButtonDisabled = computed(() => {
      if (paymentOptions.value.includes(PAYMENT_OPTIONS_INTERFACE.TERMINAL)) {
        return true;
      }

      return false;
    });

    if (savedPaymentOptions && savedPaymentOptions.length) {
      paymentOptions.value = savedPaymentOptions;
    }

    // const clearDescription = () => {
    //   savedDescriptionValue.value = null;
    // };

    const logo = computed(() => {
      if (!clientDetails.value) return null;
      if (clientDetails?.value?.whitelabel?.logo_url) {
        if (clientDetails?.value?.whitelabel?.logo_url?.startsWith('http')) {
          return clientDetails?.value?.whitelabel?.logo_url;
        } else {
          return (
            'data:image/png;base64, ' +
            clientDetails?.value?.whitelabel?.logo_url
          );
        }
      }
      return null;
    });

    const fieldsData = computed(() => {
      console.log('Finalized style object', JSON.stringify(pageDesign));
      const data = {
        paymentType: paymentType.value,
        amount: amount.value,
        description: JSON.stringify(description.value || '').replaceAll(
          '%',
          REPLACE_PERCENT_VALUE
        ),
        newWindow: true,
        fromLink: true,
        start: startDate.value,
        end: endDate.value,
        cycles: cycles.value,
        frequency: 1,
        interval: interval.value,
        email: email.value,
        paymentOptions: paymentOptions.value,
        saveMode: saveMode.value,
        publishableKey: publishableKey.value,
        savePaymentToken: allowCustomerToSaveCreditCards.value,
        terminal: selectedTerminal.value,
        showEmail: showEmail.value,
        trial: trialPeriod.value,
        zipMode: zipMode.value,
        source: SOURCE,
        fullName: showFullName.value,
        billingAddress: showBillingAddress.value,
        optIn: showEmailOptIn.value,
        emailOption: emailAddressOption.value,
        phoneOption: phoneOption.value,
        showDescription: showDescription.value,
        styles: generateStylesObjectForIFrame(pageDesign)
      };

      if (paymentType.value === PAYMENT_TYPES.CHARGE) {
        data.allowTip = allowTip.value;
      }

      if (showShippingAddress.value) {
        data.address = true;
      }

      if (levelThreeDataIsEnabled.value) {
        data.isLevelTwoDataEnabled = true;
        data.isLevelThreeDataEnabled = true;
      }

      return data;
    });

    function b64link() {
      const stringified = JSON.stringify(fieldsData.value);
      const asBase64 = btoa(stringified);
      if (sandboxMode.value) {
        return `https://sandbox.tripleplaypay.com/iframe/payment?params=${asBase64}`;
      } else {
        return `https://www.tripleplaypay.com/iframe/payment?params=${asBase64}`;
      }
    }

    const buildLink = async (saveValues) => {
      let urlb64 = b64link();

      const getUrl = () => {
        return apiService
          .get(`/api/urls?url=${urlb64}`, {
            headers: {
              Authorization: `Bearer ${apikey.value}`
            }
          })
          .then((res) => {
            console.log(res);
            return res;
          });
      };

      const api_response = await getUrl();
      return api_response.message;
    };

    const getSourceCode = async () => {
      // const result = await validate();

      // if (!result.valid) {
      // showMessage({
      //   title: 'Error',
      //   text: "Please check the information you've entered.",
      //   type: 'danger'
      // });

      // return;
      // }

      handleShowSourceCode(fieldsData.value);
    };

    const getQrCode = ({ url, icon }) => {
      return apiService
        .get(`/api/qr?text=${url}&icon=${icon}&width=150&height=150`, {
          headers: {
            Authorization: `Bearer ${apikey.value}`
          },
          onlyLoggedIn: false
        })
        .then((res) => res?.message[1].qr);
    };

    const generateLink = async (saveValues) => {
      const url = await buildLink(true);

      if (!url) {
        return;
      }

      const qrcode = await getQrCode({
        url,
        icon: 'static/images/LogoIcon_Stroke.png'
      });

      if (saveValues) {
        if (description.value) {
          localStorage.setItem(
            SAVED_DESCRIPTION_KEY,
            JSON.stringify(description.value)
          );
        }

        localStorage.setItem(
          SAVED_PAYMENT_OPTIONS_KEY,
          JSON.stringify(paymentOptions.value)
        );

        savedDescriptionValue.value = description.value;
      }

      emit('success', { url, qrcode });
      // setTimeout(() => {
      //   localStorage.setItem(API_KEY_LS_NAME, apiKey.value);
      //   apiKey.value = storedApiKey || localStorage.getItem(API_KEY_LS_NAME);
      // });
    };

    const openInNewWindow = async () => {
      let url = b64link();
      if (!url) {
        return;
      }

      window.open(url, '_blank');
    };

    const changePaymentOptions = (v, disabled) => {
      if (disabled) {
        return;
      }

      const index = paymentOptions.value.indexOf(v);

      if (index > -1) {
        paymentOptions.value.splice(index, 1);
        return;
      }

      paymentOptions.value.push(v);
    };

    const disableOption = (optionName, disabled) => {
      if (disabled) {
        return;
      }

      if (!paymentOptions.value.includes(optionName)) {
        return;
      }

      changePaymentOptions(optionName);
    };

    const enableOption = (optionName, disabled) => {
      if (disabled) {
        return;
      }

      if (paymentOptions.value.includes(optionName)) {
        return;
      }

      changePaymentOptions(optionName);
    };

    const changePaymentType = (v) => {
      paymentType.value = v;

      handlePaymentTypeChange(v);

      if (paymentType.value === PAYMENT_TYPES.SUBSCRIPTION) {
        disableOption('bank');
        disableOption('crypto');
        disableOption('terminal');
        // disableOption('digital');
      }
    };

    // const setDefaultPaymentOptions = () => {
    //   availablePaymentOptions.value = {
    //     crypto: true,
    //     credit_card: true,
    //     bank: true,
    //     terminal: true
    //   };

    //   paymentOptions.value = ['crypto', 'credit_card', 'bank', 'terminal'];
    // };

    // const handleApiKeyBlur = () => {
    //   validateApiKey();
    // };

    const isValidUUID = (uuid) => {
      if (uuid) {
        const regex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
        return regex.test(uuid);
      } else {
        return false;
      }
    }

    const getPublishableKey = () => {
      /**
       * If window.location.href value ends with a UUID, use THAT as the
       * key value to query against from the API instead of whatever has
       * come in natively through the bearer token.
       *
       * We can do this by making a POST request off to the end item.
       * @type {boolean}
       */
      loadingPublishableKey.value = true;

      // Use the URL API to create a new URL object
      const urlObj = new URL(window.location.href);

      // Extract the pathname part of the URL
      const pathname = urlObj.pathname;

      // Split the pathname into parts using '/' as the delimiter
      const parts = pathname.split('/');

      // The UUID is expected to be the last part of the pathname
      const uuid = parts[parts.length - 1];

      // Verify this is a valid value
      const isUUIDValid = isValidUUID(uuid);

      if (isUUIDValid) {
        apikey.value = uuid;
        return apiService
            .get(`api/apikeys/publishable-key`, {
              headers: {
                Authorization: `Bearer ${uuid}`
              }
            })
            .then((res) => {
              loadingPublishableKey.value = false;
              publishableKey.value = res.message?.publishable_key;
              return res.message?.publishable_key;
            })
            .catch(() => {
              loadingPublishableKey.value = false;
              publishableKeyError.value = true;
            });
      } else {
        return apiService
          .get(`api/apikeys/publishable-key`, {
            headers: {
              Authorization: `Bearer ${apikey.value}`
            },
            onlyLoggedIn: false
          })
          .then((res) => {
            loadingPublishableKey.value = false;
            publishableKey.value = res.message?.publishable_key;
            return res.message?.publishable_key;
          })
          .catch(() => {
            loadingPublishableKey.value = false;
            publishableKeyError.value = true;
          });
      }
    };

    const getClientDetails = () => {
      // if (!apiKey.value) {
      //   setDefaultPaymentOptions();
      //   return Promise.reject();
      // }

      loadingAvailablePaymentOptions.value = true;
      return apiService
        .get(`api/client/link-config`, {
          headers: {
            Authorization: `Bearer ${apikey.value}`
          },
          onlyLoggedIn: false
        })
        .then((res) => {
          loadingAvailablePaymentOptions.value = false;
          const configuration = res?.message?.merchant?.configuration || res;
          clientDetails.value = res.message;
          levelThreeDataIsAvailable.value = configuration?.level_three_data_enabled || false;

          if (levelThreeDataIsAvailable.value === true) {
            levelThreeDataIsEnabled.value = true;
          }

          availablePaymentOptions.value = {
            // crypto: !!details.config.crypto,
            credit_card: paymentMethodAvailable(configuration.payment),
            bank: paymentMethodAvailable(configuration.ach),
            // digital: true,
            terminal: false,
            swipe: true
          };

          if (!availablePaymentOptions.value.crypto) {
            disableOption('crypto');
          }

          if (!availablePaymentOptions.value.credit_card) {
            disableOption('credit_card');
          }

          if (!availablePaymentOptions.value.bank) {
            disableOption('bank');
          }

          return res.message;
        })
        .catch(() => {
          loadingAvailablePaymentOptions.value = false;

          localStorage.removeItem(API_KEY_LS_NAME);
        });
    };

    const updateClientData = () => {
      getClientDetails().then(() => {
        fetchTerminals().then(() => {
          if (!terminals.value || !terminals.value.length) {
            availablePaymentOptions.value.terminal = false;
            disableOption('terminal');
            return;
          }

          availablePaymentOptions.value.terminal = true;

          if (paymentType.value === PAYMENT_TYPES.SUBSCRIPTION) {
            return;
          }

          enableOption('terminal');
        });
      });
    };

    // const handleApiKeyUpdate = debounce(() => {
    //   updateClientData();
    // }, 1000);

    const filteredPaymentOptions = computed(() => {
      return PAYMENT_OPTIONS.filter((option) => {
        return !!availablePaymentOptions.value[option.value];
      });
    });

    // const handleChangeSubscriptionOptions = () => {
    //   const dateValues = getSubscriptionDate({
    //     startDate: initialStartDate.value,
    //     cycles: cycles.value,
    //     frequency: frequency.value,
    //     interval: interval.value,
    //     trialPeriod: trialPeriod.value
    //   });

    //   if (!dateValues) {
    //     return;
    //   }

    //   endDate.value = dateValues.end;
    // };

    const handleChangeSubscriptionOptions = () => {
      if (showTrialPeriod) {
        const dateValues = getSubscriptionDate({
          cycles: cycles.value,
          frequency: 1,
          interval: interval.value,
          trialPeriod: trialPeriod.value
        });

        if (!dateValues) {
          return;
        }

        endDate.value = dateValues.end;
      } else {
        const dateValues = getSubscriptionDate({
          startDate: startDate.value,
          cycles: cycles.value,
          frequency: 1,
          interval: interval.value
        });

        if (!dateValues) {
          return;
        }

        endDate.value = dateValues.end;
      }
    };

    onMounted(() => {
        /**
         * If window.location.href value ends with a UUID, use THAT as the
         * key value to query against from the API instead of whatever has
         * come in natively through the bearer token.
         *
         * We can do this by making a POST request off to the end item.
         * @type {boolean}
         */
        loadingPublishableKey.value = true;

        // Use the URL API to create a new URL object
        const urlObj = new URL(window.location.href);

        // Extract the pathname part of the URL
        const pathname = urlObj.pathname;

        // Split the pathname into parts using '/' as the delimiter
        const parts = pathname.split('/');

        // The UUID is expected to be the last part of the pathname
        const uuid = parts[parts.length - 1];

        // Verify this is a valid value
        const isUUIDValid = isValidUUID(uuid);

        if (isUUIDValid) {
          apikey.value = uuid;
        }
      initialStartDate.value = DateTime.now().toFormat('yyyy-MM-dd');
      startDate.value = DateTime.now().toFormat('yyyy-MM-dd');
      getPublishableKey();
      updateClientData();
    });

    return {
      handleChangeSubscriptionOptions,
      availablePaymentOptions,

      disabledEmailOptions,

      // sandbox test check
      sandboxMode,
      linkBtnText,
      pageBtnText,

      //client details
      clientDetails,

      // terminals
      terminals,
      selectedTerminal,
      loadingTerminals,

      // editor,
      // globalOptions,

      // Separate window insted iframe
      openInNewWindow,

      // Frequency
      // showFrequencyText,
      // frequencyText,

      // tip
      allowTip,

      // make zip required or hidden
      zipMode,

      // Show email field or not
      showEmail,

      // description
      description,
      savedDescriptionValue,
      clearDescription,
      descriptionError,

      logo,

      title,
      loadingAvailablePaymentOptions,
      filteredPaymentOptions,
      amount,
      generateLink,
      titleError,
      amountError,
      validateTitle,
      validateAmount,
      validateDescription,
      // handleMousePositionOnEditor,
      // currentColor,

      // create button is disabled
      createLinkButtonDisabled,

      // subscription
      showTrialPeriod,
      trialPeriod,
      trialPeriodError,
      validateTrialPeriod,
      startDate,
      endDate,
      startDateError,
      validateStartDate,
      endDateError,
      validateEndDate,
      cycles,
      cyclesError,
      validateCycles,
      frequency,
      frequencyError,
      validateFrequency,
      interval,
      intervalError,
      validateInterval,
      INTERVAL_OPTIONS,

      showFullName,
      showBillingAddress,
      showDescription,
      emailAddressOption,
      saveMode,
      phoneOption,
      showEmailOptIn,
      // Credit cards/bank token
      allowCustomerToSaveCreditCards,
      // Address
      showShippingAddress,
      pageDesign,
      resetStyles,
      resetComponentStyles,
      setComponentStyles,
      levelThreeDataIsEnabled,
      levelThreeDataIsAvailable,
      // apiKey,
      // apiKeyError,
      // validateApiKey,
      // handleApiKeyBlur,
      // handleApiKeyUpdate,
      // showApiKey,
      paymentType,
      changePaymentType,
      PAYMENT_TYPES,
      email,
      emailError,
      validateEmail,
      PAYMENT_OPTIONS,
      paymentOptions,
      paymentOptionsError,
      changePaymentOptions,
      moveEmailOptionTo,

      getSourceCode,
      showSourceCodeModal,
      sourceCodeValue,

      CRYPTO_TYPES,
      ZIP_CODE_OPTIONS,
      WITH_OPTIONAL
    };
  },
  watch: {
    paymentOptions() {
      this.checkFlag();
    }
  },
  methods: {
    checkFlag() {
      if (Object.values(this.paymentOptions).includes('crypto')) {
        this.emailAddressOption = 'optional';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.logo {
  height: 70px;

  img {
    height: 100%;
  }
}
.hr {
  margin-bottom: 0;
  background-color: #d9d9d9 !important;
  opacity: 1;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.generate-btn,
.generate-placeholder {
  width: 116px;
}
.ask-custom-label {
  width: 250px;
}

.ask-default-label {
  width: 135px;
}

::v-deep(.ask-option-wide) {
  .ask-container {
    display: flex;
    justify-content: space-between;
  }
  .label {
    width: 250px;
  }
}
.checkbox {
  margin-bottom: 10px;
}
.new-link-page {
  max-width: 500px;
}
.subs-label {
  font-size: 14px;
}
.terminals-loader-container {
  min-height: 66px;
}

.options-container {
  position: relative;
  min-height: 40px;
}

.show-link-btn {
  background: var(--c-white);
  color: var(--c-primary) !important;

  &:hover {
    background: var(--c-primary) !important;
    color: var(--c-white) !important;
  }
}

.trial-period {
  width: 150px;
}

::v-deep(.trial-period) {
  .input {
    height: 40px;
  }
}
.question {
  margin-top: -2px;
}

.title {
  font-size: 20px;
}

.open {
  cursor: pointer;
  color: var(--c-primary);
}

.get-code-el {
  color: var(--c-dark) !important;
  font-size: 14px;
  text-align: center;
}
</style>

<style lang="scss">
.new-link-page {
  .modal-dialog {
    max-width: 750px !important;
  }
}
</style>
