<template>
  <h1>Admin Reporting</h1>
  <!--
  <div style="z-index: 99999 !important;">
    <base-spinner v-if="store.state.routerLoading"></base-spinner>
  </div>
  -->

  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <div class="">
        <ul class="navbar-nav" style="flex-wrap: wrap;">
          <li class="nav-item"><router-link :to="'/admin-reporting'" class="nav-link">Home</router-link></li>
          <li class="nav-item"><router-link :to="'/admin-reporting/with-snowflake'" class="nav-link">With Snowflake</router-link></li>
          <!--
          <li class="nav-item"><router-link :to="'/admin-reporting/all-internal/example1'" class="nav-link">Internal Example1</router-link></li>
          -->
          <li
            v-for="sourceName in allInternalSourceNames"
            :key="sourceName"
            class="nav-item"
          >
            <!--<router-link :to="`/admin-reporting/all-internal/${sourceName}`" class="nav-link">-->
            <router-link :to="`/admin-reporting/all-internal/${sourceName}`" class="nav-link" style="padding: 3px;">
              <button
                class="btn btn-sm btn-secondary"
              >
                {{ sourceName?.replaceAll(/^reporting\./g, "") }}
              </button>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <!--
  <div style="z-index: 99999 !important; min-width: 100px; min-height: 100px; pointer-events: none; cursor: wait;">
  </div>
  -->
  <div style="position: relative; height: 100%; width: 100%">
    <div v-if="store.state.routerLoading" style="position: absolute; height: 100%; width: 100%; z-index: 10; cursor: wait;">
      <base-spinner v-if="always"></base-spinner>
    </div>
    <div style="position: absolute; height: 100%; width: 100%;">
      <router-view></router-view>
    </div>
  </div>
</template>
<script setup>
import BaseSpinner from '@/components/base/BaseSpinner.vue';
import { useStore } from 'vuex';
import { ref } from 'vue';
import { computedAsync } from '@vueuse/core';
import http from '@/services/http';
const always = ref(true);
const store = useStore();

const allInternalSourceNames = computedAsync(async () => {
  const response = await http.get("/api/v1/report/all-internal");
  return response.data.message;
}, []);
</script>
<style>
.router-link-active, .router-link-exact-active {
  font-weight: bold;
}
</style>
